<template>
  <div>
    <div
      show
      variant="light"
      class="alert alert-custom alert-white alert-shadow fade show gutter-b justify-content-end"
    >
      <b-col>
        <b-row>
          <b-col>
            <b-form-group label="Từ ngày">
              <b-form-input
                :id="`type-date`"
                :type="`date`"
                v-model="filter.date_from"
              ></b-form-input>
            </b-form-group>
          </b-col>
          <b-col>
            <b-form-group label="Đến ngày">
              <b-form-input
                :id="`type-date`"
                :type="`date`"
                v-model="filter.date_to"
              ></b-form-input>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Campaign">
              <b-form-input v-model="filter.campaign"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Voucher">
              <b-form-input v-model="filter.voucher"></b-form-input>
            </b-form-group>
          </b-col>

          <b-col>
            <b-form-group label="Trạng thái">
              <b-form-select :options="status" v-model="filter.status"></b-form-select>
            </b-form-group>
          </b-col>
        </b-row>

        <b-col class="pt-2"><label></label></b-col>
        <b-col class="text-right">
          <b-button-group>
            <b-button type="button" variant="primary" @click="search()">
              <i class="la la-search"></i>
              <span>Tìm kiếm</span>
            </b-button>

            <b-button
              type="button"
              variant="success"
              @click="$bvModal.show(modalId)"
            >
              <i class="la la-file-export"></i>
              <span>Xuất file</span>
            </b-button>
          </b-button-group>
        </b-col>
      </b-col>
    </div>

    <b-card>
      <b-card-body>
        <div class="mb-3">
          <b-row>
            <b-col><span class="mr-2">Số lượng voucher:</span><a v-if="analytics.total === null" href="javascript:void(0)" @click="getAnalytics('COUNT_TOTAL')"><i class="fa fa-eye"></i></a><strong v-else>{{ numberFormat(analytics.total) }}</strong></b-col>
            <b-col><span class="mr-2">Số lượng voucher đã sử dụng:</span><a v-if="analytics.used === null" href="javascript:void(0)" @click="getAnalytics('COUNT_VOUCHER_USED')"><i class="fa fa-eye"></i></a><strong v-else>{{ numberFormat(analytics.used) }}</strong></b-col>
            <b-col><span class="mr-2">Giá trị voucher:</span><a v-if="analytics.sumValue === null" href="javascript:void(0)" @click="getAnalytics('SUM_VOUCHER_VALUE')"><i class="fa fa-eye"></i></a><strong v-else>{{ numberFormat(analytics.sumValue) }}</strong></b-col>
            <b-col><span class="mr-2">Giá trị đã sử dụng:</span><a v-if="analytics.sumValueUsed === null" href="javascript:void(0)" @click="getAnalytics('SUM_VOUCHER_VALUE_USED')"><i class="fa fa-eye"></i></a><strong v-else>{{ numberFormat(analytics.sumValueUsed) }}</strong></b-col>
            <b-col><span class="mr-2">Số lượng voucher còn lại:</span><a v-if="analytics.available === null" href="javascript:void(0)" @click="getAnalytics('COUNT_VOUCHER_AVAILABLE')"><i class="fa fa-eye"></i></a><strong v-else>{{ numberFormat(analytics.available) }}</strong></b-col>
          </b-row>
        </div>

        <b-table
          :items="items"
          :fields="fields"
          ref="table"
          striped
          hover
          responsive
          caption-top
        >
          <template #cell(index)="data">
            {{ (currentPage - 1) * 25 + (data.index + 1) }}
          </template>

          <template #cell(discount_amount)="data">
            {{ numberFormat(data.item.discount_amount) }}
          </template>
          <template #cell(real_discount)="data">
            {{ numberFormat(data.item.real_discount) }}
          </template>
          <template #cell(used)="data">
            {{ numberFormat(data.item.used) }}
          </template>
          <template #cell(used_by_time)="data">
            {{ numberFormat(data.item.used_by_time) }}
          </template>
          <template #cell(used_value)="data">
            {{ numberFormat(data.item.discount_amount * data.item.used_by_time) }}
          </template>
          <template #cell(coupon_remaining)="data">
            {{ numberFormat(data.item.max_gift - data.item.used) }}
          </template>
          <template #cell(status)="data">
            {{ handleStatus(data.item.start_at, data.item.end_at) }}
          </template>
        </b-table>
        <div>
          <b-pagination-nav
            v-model="currentPage"
            :link-gen="createLinkPage"
            :number-of-pages="paginate.totalPage"
            use-router
            first-number
            last-number
            align="right"
          ></b-pagination-nav>
        </div>
      </b-card-body>
    </b-card>

    <alert-dialogue ref="confirmDialogue"></alert-dialogue>
    <export-modal :modal-id="modalId" @exportData="exportData"></export-modal>
  </div>
</template>

<script>
import { mapGetters } from "vuex";
import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";
import Common from "../../../../core/mixins/common";
import RepositoryFactory from "@/core/repositories/repositoryFactory";
import AlertDialogue from "@/view/pages/components/AlertModal.vue";
import ExportModal from "@/view/pages/components/ExportModal.vue";
const BiRepository = RepositoryFactory.get("Bi");
import router from "@/router";

export default {
  name: "Index",
  components: { ExportModal, AlertDialogue },
  mixins: [Common],
  data() {
    return {
      analytics: {
        total: null,
        used: null,
        available: null,
        sumValue: null,
        sumValueUsed: null
      },
      currentPage: 1,
      paginate: {
        limit: 25,
        page: 1,
        totalPage: 1,
        total: 0,
        skip: 0,
      },
      modalId: "export_report_coupon",
      items: [],
      fields: [
        {
          key: "index",
          label: "STT",
        },
        {
          key: "sub_description",
          label: "Campaign",
        },
        {
          key: "name",
        },
        {
          key: "discount_amount",
          label: "Voucher",
        },
        {
          key: "start_at",
          label: "Ngày bắt đầu",
        },
        {
          key: "end_at",
          label: "Ngày kết thúc",
        },
        {
          key: "used",
          label: "Số lượng Voucher thực dùng",
        },
        {
          key: "used_by_time",
          label: "Số lượng đã sử dụng",
        },
        {
          key: "used_value",
          label: "Giá trị Voucher",
        },
        {
          key: "real_discount",
          label: "Giá trị thực tế đã sử dụng",
        },
        {
          key: "coupon_remaining",
          label: "Số lượng còn lại",
        },
        {
          key: "status",
          label: "Trạng thái Campaign",
        },
      ],
      filter: {
        date_from: this.getFirstDayOfMonth(),
        date_to: this.getCurrentDay(),
        campaign: null,
        voucher: null,
        status: null,
      },
      status: [
        { value: "", text: "Tất cả" },
        { value: "ACTIVATE", text: "Đang hoạt động" },
        { value: "PENDING", text: "Chưa hoạt động" },
        { value: "EXPIRED", text: "Đã kết thúc" },
      ],
    };
  },
  methods: {
    search() {
      this.filter.page = 1;
      router.push({ path: "/report/coupon", query: this.filter }).catch(() => {});

      if (this.currentPage !== 1) {
        this.currentPage = 1;
        return;
      }

      this.getList();
    },
    async getList() {
      this.$bus.$emit("show-loading", true);
      this.items = [];

      this.analytics.total = null;
      this.analytics.used = null;
      this.analytics.available = null;
      this.analytics.sumValue = null;
      this.analytics.sumValueUsed = null;

      let params = this.convert(this.filter);
      params.page = this.currentPage;
      let response = await BiRepository.getListCoupon(params);
      let body = response.data;

      if (body.error_code) {
        this.notifyAlert("warn", response.data.message);
        this.$bus.$emit("show-loading", false);
        return true;
      }

      this.items = body.data.data;
      this.paginate.totalPage = body.data.last_page;
      this.paginate.total = body.data.total;
      this.paginate.page = body.data.current_page;
      this.paginate.limit = body.data.per_page;
      this.$bus.$emit("show-loading", false);
    },
    async exportData(description = null) {
      this.$bvModal.hide(this.modalId);
      this.$bus.$emit("show-loading", true);
      this.errorMessage = null;
      let params = this.convert(this.filter);
      params.export_desc = description
      let response = await BiRepository.exportListCoupon(params);
      this.$bus.$emit("show-loading", false);
      let body = response.data;
      if (body.error_code) {
        this.notifyAlert("warn", response.data.message);
        return true;
      }

      this.$bus.$emit("show-loading", false);
      let link = window.location.origin + "/#/reconcile/export-data";
      let cfm = await this.$refs.confirmDialogue.show({
        title: "Download File",
        message: "File đang được xử lý, bạn có thể tải xuống sau ít phút <a href='" + link + "' target='_blank'>TẠI ĐÂY</a>",
        okButton: "Ok",
      });

      if (!cfm) {
        self.$bus.$emit("show-loading", false);
        return (self.errorMessage = "Có lỗi khi lấy thông tin");
      }
    },
    async getAnalytics(type = "COUNT_TOTAL") {
      this.$bus.$emit("show-loading", true);
      let params = this.convert(this.filter);
      params.type = type;
      let response = await BiRepository.getCouponAnalytics(params);
      this.$bus.$emit("show-loading", false);
      let body = response.data;

      if (body.error_code) {
        this.notifyAlert("warn", response.data.message);
        return true;
      }

      return this.handleType(type, body.data);
    },
    handleType(type, data) {
      if (type === "COUNT_TOTAL") {
        this.analytics.total = data > 0 ? data : 0;
        return;
      }

      if (type === "COUNT_VOUCHER_USED") {
        this.analytics.used = data > 0 ? data : 0;
        return;
      }

      if (type === "COUNT_VOUCHER_AVAILABLE") {
        this.analytics.available = data > 0 ? data : 0;
        return;
      }

      if (type === "SUM_VOUCHER_VALUE") {
        this.analytics.sumValue = data > 0 ? data : 0;
        return;
      }

      if (type === "SUM_VOUCHER_VALUE_USED") {
        this.analytics.sumValueUsed = data > 0 ? data : 0;
      }
    },
    convert(params) {
      Object.keys(params).forEach((key) => {
        if (params[key] === null || params[key] == "") {
          delete params[key];
        }
      });
      return params;
    },
    handleStatus(startDate, endDate) {
      let today = new Date();

      if (new Date(startDate) > today) {
        return "Chưa hoạt động";
      }

      if (new Date(endDate) < today) {
        return "Đã kết thúc";
      }

      return "Đang hoạt động";
    },
  },
  computed: {
    ...mapGetters(["layoutConfig"]),

    config() {
      return this.layoutConfig();
    },
  },
  watch: {
    currentPage() {
      this.getList(this.currentPage);
    },
  },
  created() {
    this.getList();
  },
  mounted() {
    this.$store.dispatch(SET_BREADCRUMB, [{ title: "Báo cáo khuyến mại" }]);
  },
};
</script>
